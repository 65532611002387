<template>

  <div>
    <h2>{{title}}</h2>

    <p>{{infoText}}</p>

    <router-link :to="{ name: 'Statistikinmatning' }">
      <button class="btn btn-primary mb-4">Huvudmeny</button>
    </router-link>

  </div>

</template>

<script>
export default {
  props: {
    initialPerson: Object,
    initialTestimony: Object,
    answerAddPerson: Boolean
  },
  data() {
    return {
      title: 'Tack. Statistiken är nu registrerad.',
      infoText: 'Målet har nummer ' + this.initialTestimony.id + '. Du kan fylla på med stödsökande kopplade till detta mål genom att leta upp det i avdelningen "Tidigare registrerat."'
    }
  },
  mounted() {
    if (!this.testimonyHasRegistedPersons) {
      this.title = 'Målet är nu registrerat.'
      this.infoText = 'Det har nummer ' + this.initialTestimony.id + '. Du kan fylla på med stödsökande kopplade till detta mål genom att leta upp det i avdelningen "Tidigare registrerat."'
    }
  },
  computed: {
    testimonyHasRegistedPersons() {
      return this.initialTestimony.persons.length
    }
  }
}
</script>

<style>

</style>
