<template>

  <div>
    <h2>Hej, detta målet finns inte!</h2>

    <router-link :to="{ name: 'Statistikinmatning', params: { formFinish: true }}">
      <button class="btn btn-primary mb-4">Huvudmeny</button>
    </router-link>

    <!-- <pre>{{initialTestimony.persons}}</pre> -->

  </div>

</template>

<script>
export default {
  props: {
    initialPerson: Object,
    initialTestimony: Object,
    answerAddPerson: Boolean
  },
}
</script>

<style>

</style>
