<template>

  <main-layout>

    <transition name="component-fade" mode="out-in" v-if="!isLoading">
      <!-- <keep-alive> -->
        <component
          :is="stepsList[currentStep]"
          :key="currentStep"
          :initialTestimony.sync="testimony"
          :initialPerson="person"
          :answerAddPerson="answerAddPerson"
          @setCurrentStep="setCurrentStep"
          @finish="setFormAsFinish"
          :refreshTestimony="refreshTestimony"
        ></component>
      <!-- </keep-alive> -->
    </transition>

  </main-layout>

</template>

<script>
import mainLayout from '@/views/Layouts/Main'
import BojAPI from '@/api/boj'
import AddNewTestimonial from '@/components/forms/AddNewTestimonial.vue'
import AddNewPeople from '@/components/forms/AddNewPeople.vue'
import AddNewTestimonialFinish from '@/components/forms/AddNewTestimonialFinish.vue'
import AddNewTestimonialNotExists from '@/components/forms/AddNewTestimonialNotExists.vue'

export default {
  components: {
    mainLayout,
    AddNewTestimonial,
    AddNewPeople,
    AddNewTestimonialFinish,
    AddNewTestimonialNotExists
  },
  data() {
    return {
      currentStep: 0,
      stepsList: ['AddNewTestimonial', 'AddNewPeople', 'AddNewTestimonialFinish', 'AddNewTestimonialNotExists'],
      testimony: {},
      person: {},
      formFinish: false,
      answerAddPerson: false,
      isLoading: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.formFinish) {
      this.$store.dispatch('setFormFinish', false);
      next();

    } else {
      this.$confirm({
        message: 'Är du säker på att du vill avbryta lägga till nytt mål?',
        button: {
          no: 'Fortsätt lägga till nytt mål',
          yes: 'Ja, jag är säker. Avbryt.',
        },
        /**
        * Callback Function
        * @param {Boolean} confirm
        */
        callback: (confirm) => {
          if (confirm) {
            if (!this.crimeFieldEmpty) {
              this.$Progress.start();

              BojAPI.deleteTestimony(this.testimony.id)
              .then(() => {
                this.$toast.success('Målet raderat', {
                  position: 'top-center',
                });
                this.$Progress.finish();
                next()
              })
            } else {
              next();
            }
          }
        }
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    BojAPI.getTestimony(to.params.id)
      .then((response) => {
        next((vm) => {
          vm.setData(response.data)
          vm.isLoading = false;
        });
      })
      .catch((error) => {
        if (error.response.status == 404) {
          next((vm) => {
            vm.isLoading = false;
            vm.currentStep = 3;
          });
        }
      })
  },
  computed: {
    crimeFieldEmpty() {
      if (!this.testimony.crime_id && !this.testimony.testimony_other_type_id) {
        return false;
      }

      return true;
    }
  },
  methods: {
    nextStep() {
      this.step++;
    },
    prevStep() {
      if (this.step != 1) {
        this.step--;
      }
    },
    setCurrentStep(step) {

      if (step == 1) {
        this.currentStep = step;

        BojAPI.addTestimonyPerson(this.testimony.id)
        .then((response) => {
          this.person = response.data
        })
        .catch((error) => {
          console.log('there was an error', error);
          this.currentStep = 0;
        })

        return;
      }

      this.currentStep = step
    },
    setFormAsFinish() {
      this.refreshTestimony().then(() => {
        this.$store.dispatch('setFormFinish', true);

        this.formFinish = true;
        this.setCurrentStep(2);
      })
    },
    refreshTestimony() {
      return BojAPI.getTestimony(this.testimony.id)
      .then((response) => {
        this.setData(response.data)
      })
      .catch((error) => {
        console.log('Error: refreshTestimony');
      })
    },
    setData(payload) {
      this.testimony = payload.data;
    }
  }
}
</script>

<style>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
