<template>
  <div>
    <header class="container-fluid bg-white">
      <div>
        <div>{{currentPage}}</div>
        <router-link to="/testimony">Meny</router-link>
      </div>
      <div>
          <div>{{ user.name }}</div>
          <router-link class="d-flex justify-content-end" to="/logout">Logga ut</router-link>
      </div>
    </header>
    <div class="container content">
        <slot/>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {

  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentPage() {
      if (this.$store.getters.formFinish) {
        return 'Ny stödsökande'
      }

      return this.$route.name;
    }
  }
}
</script>
