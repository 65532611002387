<template>
  <main-layout>

    <transition name="fade" mode="out-in">
      <div  v-if="!isSubRoute">
        <h3>Vad vill du ändra på mål #{{testimonyId}}?</h3>

        <div class="widget overview">
          <div class="widget-body bt-1 pr-0 pl-3">

            <div class="container-fluid">
              <div class="row">
                <div class="col-xs-12 col-md-3">
                  <div class="overview-main">
                    <div class="kt-profile__main-info">
                      <div class="overview-main-title">#{{testimony.id}} </div>

                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md">
                  <div class="list overview-middle pb-2">
                    <div class="list-item">
                      <label>Domstol</label>
                      <span v-if="testimony.court">{{ testimony.court.label }}</span>
                      <span v-if="!testimony.court">-</span>
                      <!-- <span class="d-inline" v-for="cr in ticket.crimerelation" :key="cr.id">{{ $to(cr,'label') }}, </span> -->
                    </div>

                  </div>
                </div>
                <div class="col-xs-12 col-md">
                  <div class="list overview-middle pb-2">
                    <div class="list-item">
                      <label>Typ av mål</label>
                      <span v-if="testimony.testimony_type">{{testimony.testimony_type.label}}</span>
                      <span v-if="!testimony.testimony_type">-</span>
                    </div>
                    <div class="list-item" v-if="isCrimeOrCivilCase('Brottmål')">
                      <label>Brottstyp</label>
                      <span v-if="testimony.crime">{{ testimony.crime.label }}</span>
                      <span v-if="!testimony.crime">-</span>
                    </div>
                    <div class="list-item" v-if="isCrimeOrCivilCase('Civilmål')">
                      <label>Civilmål</label>
                      <span v-if="testimony.testimony_other_type">{{ testimony.testimony_other_type.label }}</span>
                      <span v-if="!testimony.testimony_other_type">-</span>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md">
                  <div class="list overview-middle pb-2">
                    <div class="list-item">
                      <label>Lokalkontor</label>
                      <span v-if="testimony.local_office">{{ testimony.local_office.label }}</span>
                      <span v-if="!testimony.local_office">-</span>
                    </div>
                    <div class="list-item">
                      <label>Brottsofferjour</label>
                      <span v-if="testimony.office">{{ testimony.office.label }}</span>
                      <span v-if="!testimony.office">-</span>
                    </div>
                    <!-- <div class="list-item">
                      <label>Målnummer</label>
                      <span v-if="testimony.case_number">{{ testimony.case_number }}</span>
                      <span v-if="!testimony.case_number">-</span>
                    </div> -->
                  </div>
                </div>
                <div class="col-auto col-md-2">
                  <div class="list overview-last pb-2">
                    <div class="list-item">
                      <label>Datum inlagt</label>
                      <span v-if="testimony.created_date_formatted">{{ testimony.created_date_formatted }}</span>
                      <span v-if="!testimony.created_date_formatted">-</span>
                    </div>
                    <div class="list-item">
                      <label>Rättegångsdatum</label>
                      <span v-if="testimony.case_date">{{ testimony.case_date }}</span>
                      <span v-if="!testimony.case_date">-</span>
                    </div>
                    <div class="list-item">
                      <label>Antal inmatade personer</label>
                      <span v-if="testimony.persons">{{ persons }}</span>
                      <span v-if="!testimony.persons">-</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div class="widget-footer with-tabs">
            <ul class="nav nav-tabs nav-tabs-line" role="tablist">
              <li class="nav-item section">
                <div class="nav-link">Inställningar</div>
              </li>
            </ul>
          </div>

        </div>

        <div style="position: relative;">
          <div class="widget">
            <div class="widget-body bt-1 pr-0 pl-3">
              <div class="container-fluid" style="color: black">
                <div class="d-flex flex-column align-items-start mt-4 mb-4">
                  <router-link class="btn btn-primary mb-4" to="testimony" append>Måluppgifter</router-link>
                  <router-link class="btn btn-primary mb-4" to="persons" append>Visa registrerade personer</router-link>
                  <button v-if="testimony.is_editable" class="btn btn-primary mb-4" @click="addPerson">Lägg till person</button>
                  <!-- <router-link class="btn btn-primary mb-4" to="add-person" append>Lägg till person</router-link> -->
                </div>
                <div class="d-flex justify-content-end">
                  <button @click="goBack" class="btn btn-primary mt-4">{{goBackLabel}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



      <router-view
        :initialTestimony="testimony"
        :initialPerson="person"
        :refreshTestimony="refreshTestimony"
      />
    </transition>

  </main-layout>
</template>

<script>
import BojAPI from '@/api/boj'
import mainLayout from '@/views/Layouts/Main'
import AddNewTestimonial from '@/components/forms/AddNewTestimonial.vue'
import AddNewPeople from '@/components/forms/AddNewPeople.vue'
import ListPeople from '@/views/Testimony/ListPeople.vue'

export default {
  components: {
    mainLayout,
    AddNewPeople,
    AddNewTestimonial,
    ListPeople
  },
  data() {
    return {
      testimonyTypes: [],
      testimony: {},
      person: {}
    }
  },
  beforeRouteEnter (to, from, next) {
     BojAPI.getTestimony(to.params.id)
      .then((response) => {
        next(vm => {
          vm.setData(response.data)
          vm.setStoreData();
        })
      })
      .catch((error) => {})
  },
  computed: {
    testimonyId() {
      return this.$route.params.id
    },
    goBackLabel() {
      return !this.isSubRoute ? "Tillbaka till tidigare registrerat" : "Tillbaka"
    },
    isSubRoute() {
      return this.$route.matched.length > 1
    },
    persons() {
      return this.testimony.persons ? this.testimony.persons.length : 0;
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Tidigare Registrerat' })
    },
    addPerson() {
      this.$Progress.start()

      BojAPI.addTestimonyPerson(this.testimonyId)
      .then((response) => {
        const personId = response.data.id
        this.$router.push({ name: 'Lägg till ny person', params: { personId: personId } })
      })
    },
    isCrimeOrCivilCase(type) {
      if (!this.testimony.testimony_type) return false;

      let selectedType = this.testimonyTypes.find(type => type.id == this.testimony.testimony_type.id);

      if (selectedType && selectedType.label == type) {
        return true;
      }

      return false
    },
    refreshTestimony() {
      return BojAPI.getTestimony(this.testimony.id)
      .then((response) => {
        this.setData(response.data)
      })
      .catch((error) => {
        this.$toast.error('Något gick fel - refreshTestimony', {
          position: 'top-center',
        });
      })
    },
    setData(payload) {
      this.testimony = payload.data;
    },
    setStoreData() {
      this.testimonyTypes = this.$store.getters.options.testimony_types;
    }
  }
}
</script>
